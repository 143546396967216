import Rails from "@rails/ujs";
Rails.start();
import "../stylesheets/application.scss";
const images = require.context("images", true);
const imagePath = (name) => images(name, true);
import NiceSelect from "nice-select2/dist/js/nice-select2";
import "nice-select2/src/scss/nice-select2.scss";

document.addEventListener("DOMContentLoaded", () => {
  const body = document.querySelector("body");
  const globalNavi = document.getElementById("globalNavi");
  const menuBtn = document.getElementById("menu-btn");

  // グローバルナビはデフォルトがアクティブになっている
  // これをスマホ幅での閲覧時のみデフォルトを非アクティブにしたいので下記の処理を実行
  if (window.innerWidth < 1000) {
    body.classList.remove("menuActive");
  }

  // 上記処理が走る際、メニュー開閉時のぬるっとしたアニメーションのために必要なcssのtransitionが
  // 悪影響(ロード後にぬるっとナビが非表示になる)を及ぼすため下記でロード後に後付けしている
  setTimeout(function () {
    if (globalNavi) {
      globalNavi.style.transition = "0.3s";
      menuBtn.style.transition = "0.3s";
    }
    const mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      mainContainer.style.transition = "0.3s";
    }
    const header = document.querySelector("header");
    if (header) {
      header.style.transition = "0.3s";
    }
  }, 600);

  if (menuBtn) {
    menuBtn.onclick = () => body.classList.toggle("menuActive");
  }

  document.getElementById("dimmer").onclick = () => body.classList.remove("menuActive");

  const toggleMenu = function () {
    const parent = this.parentNode;
    if (parent.classList.contains("active")) {
      parent.classList.remove("active");
    } else {
      parent.classList.add("active");
    }
  };

  document.querySelectorAll(".naviItemHeader").forEach(function (header) {
    header.addEventListener("click", toggleMenu);
  });

  const changeMenu = function (tabId) {
    const url = `${location.pathname}?tab=${tabId}`;
    history.pushState(null, null, url);
    const editCreatorMenus = document.querySelectorAll(".editCreatorMenu");
    if (editCreatorMenus) {
      editCreatorMenus.forEach(function (menu) {
        if (menu.dataset.id == tabId) {
          menu.classList.add("active");
        } else {
          menu.classList.remove("active");
        }
      });
    }
  };

  const flashMessage = document.getElementById("flashMessage");
  if (flashMessage) {
    setTimeout(function () {
      flashMessage.classList.add("fadeout");
      setTimeout(function () {
        flashMessage.style.display = "none";
      }, 500);
    }, 5000);
  }

  document.querySelectorAll(".fileInput").forEach(function (input) {
    input.addEventListener("change", function (event) {
      const input = event.target;
      let text = "";
      if (input.files.length >= 2) {
        text = input.files.length + "ファイル";
      } else {
        text = input.files[0]?.name ?? "ドラッグアンドドロップするか、クリックしてファイルを選択してください";
      }
      const label = input.nextElementSibling;
      label.textContent = text;
    });
  });

  const userAddBtn = document.getElementById("userAddBtn");
  const eventUserParent = document.getElementById("eventUserParent");
  const userData = document.getElementById("userData");
  let eventUserSelectCount = document.querySelectorAll(".eventUserSelect").length;
  if (userData) {
    const userArray = JSON.parse(userData.dataset.users);
    userAddBtn.addEventListener("click", function () {
      const selectParent = document.createElement("div");
      selectParent.classList.add("eventUserSelectParent");
      const select = document.createElement("select");
      select.required = true;
      select.classList.add("form-select");
      select.classList.add("eventUserSelect");
      select.name = `event[event_users_attributes][${eventUserSelectCount}][user_id]`;
      const defaultOption = document.createElement("option");
      select.appendChild(defaultOption);
      userArray.forEach(function (user) {
        const option = document.createElement("option");
        option.value = user[1];
        option.textContent = user[0];
        select.appendChild(option);
      });
      selectParent.appendChild(select);
      const closeBtn = document.createElement("div");
      closeBtn.classList.add("closeBtn");
      closeBtn.textContent = "削除";
      closeBtn.addEventListener("click", function () {
        selectParent.remove();
      });
      selectParent.appendChild(closeBtn);
      eventUserParent.appendChild(selectParent);
      eventUserSelectCount = document.querySelectorAll(".eventUserSelect").length;
    });
  }

  document.querySelectorAll(".closeBtn.userRegisterd").forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      const checkbox = event.target.previousElementSibling;
      checkbox.checked = true;
      event.target.parentNode.classList.add("hide");
    });
  });

  const influencerAddBtn = document.getElementById("influencerAddBtn");
  const eventInfluencerParent = document.getElementById("eventInfluencerParent");
  const influencerData = document.getElementById("userData");
  let eventInfluencerSelectCount = document.querySelectorAll(".eventInfluencerSelect").length;
  if (influencerData) {
    const influencerArray = JSON.parse(influencerData.dataset.influencers);
    influencerAddBtn.addEventListener("click", function () {
      const selectParent = document.createElement("div");
      selectParent.classList.add("eventInfluencerSelectParent");
      let select = document.createElement("select");
      select.required = true;
      select.classList.add("form-select");
      // select.setAttribute("id", "searchSelect");
      select.classList.add("eventInfluencerSelect");
      select.name = `event[event_influencers_attributes][${eventInfluencerSelectCount}][influencer_id]`;
      const defaultOption = document.createElement("option");
      select.appendChild(defaultOption);
      influencerArray.forEach(function (influencer) {
        const option = document.createElement("option");
        option.value = influencer[1];
        option.textContent = influencer[0];
        select.appendChild(option);
      });
      selectParent.appendChild(select);
      new NiceSelect(select, { searchable: true });
      const closeBtn = document.createElement("div");
      closeBtn.classList.add("closeBtn");
      closeBtn.textContent = "削除";
      closeBtn.addEventListener("click", function () {
        selectParent.remove();
      });
      selectParent.appendChild(closeBtn);
      eventInfluencerParent.appendChild(selectParent);
      eventInfluencerSelectCount = document.querySelectorAll(".eventInfluencerSelect").length;
    });
  }

  document.querySelectorAll(".closeBtn.influencerRegisterd").forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      const checkbox = event.target.previousElementSibling;
      checkbox.checked = true;
      event.target.parentNode.classList.add("hide");
    });
  });

  document.querySelectorAll(".modalBtn").forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      const modalId = event.target.dataset.modal_id;
      const modal = document.getElementById(modalId);
      modal.classList.add("active");
    });
  });
  document.querySelectorAll(".modal-close").forEach(function (overlay) {
    overlay.addEventListener("click", function () {
      document.querySelectorAll(".modal").forEach(function (modal) {
        modal.classList.remove("active");
      });
    });
  });
  document.querySelectorAll("input[type='number']").forEach(function (input) {
    input.addEventListener("change", function (e) {
      if (e.target.value.length > 19) {
        alert("入力可能な桁数を超えています");
        e.target.value = "";
      }
    });
  });

  const searchSelect = document.getElementById("searchSelect");
  if (searchSelect) {
    new NiceSelect(searchSelect, { searchable: true });
  }

  document.querySelectorAll(".searchSelect").forEach(function (select) {
    new NiceSelect(select, { searchable: true });
  });

  const export_ids = document.getElementById("export_ids");
  if (export_ids) {
    let export_ids_array = [];
    document.querySelectorAll(".export_check").forEach(function (check) {
      check.addEventListener("change", function (e) {
        export_ids_array = [];
        document.querySelectorAll(".export_check").forEach(function (c) {
          if (c.checked) {
            export_ids_array.push(c.dataset.id);
          }
        });
        export_ids.value = export_ids_array;
      });
    });
  }

  const name_array = document.getElementById("name_array");
  if (name_array) {
    const youtube_url_array = document.getElementById("youtube_url_array");
    const x_url_array = document.getElementById("x_url_array");
    const name_values = JSON.parse(name_array.dataset.values);
    const youtube_url_values = JSON.parse(youtube_url_array.dataset.values);
    const x_url_values = JSON.parse(x_url_array.dataset.values);
    const warning = document.getElementById("warning");
    const negative = document.getElementById("negative");
    const name = document.getElementById("name");
    name.addEventListener("keyup", function (e) {
      if (name_values.includes(name.value)) {
        warning.style.display = "inline-block";
      } else {
        warning.style.display = "none";
      }
    });
    let yt_valid = true;
    let x_valid = true;
    const youtube_url = document.getElementById("youtube_url");
    const not_impl_influencer_submit_btn = document.getElementById("not_impl_influencer_submit_btn");
    youtube_url.addEventListener("keyup", function (e) {
      if (youtube_url.value && youtube_url_values.includes(youtube_url.value)) {
        yt_valid = false;
      } else {
        yt_valid = true;
      }
      validInfluencerForm(yt_valid, x_valid, negative, not_impl_influencer_submit_btn);
    });
    const x_url = document.getElementById("x_url");
    x_url.addEventListener("keyup", function (e) {
      if (x_url.value && x_url_values.includes(x_url.value)) {
        x_valid = false;
      } else {
        x_valid = true;
      }
      validInfluencerForm(yt_valid, x_valid, negative, not_impl_influencer_submit_btn);
    });
  }

  const event_url_array = document.getElementById("event_url_array");
  if (event_url_array) {
    const event_url_values = JSON.parse(event_url_array.dataset.values);
    const warning = document.getElementById("warning");
    const event_url = document.getElementById("event_url");
    event_url.addEventListener("keyup", function (e) {
      if (event_url.value && event_url_values.includes(event_url.value)) {
        warning.style.display = "inline-block";
      } else {
        warning.style.display = "none";
      }
    });
  }
});

window.validInfluencerForm = function (yt_valid, x_valid, negative, submit_btn) {
  if (yt_valid && x_valid) {
    negative.style.display = "none";
    submit_btn.disabled = false;
  } else {
    negative.style.display = "inline-block";
    submit_btn.disabled = true;
  }
};

window.showErrorDialog = function (msg) {
  alert(`エラーが発生しました\n${msg}`);
};

window.showDialog = function (msg) {
  alert(msg);
};
